<template>
    <v-container grid-list-lg fluid class="extras-style">
        <v-row justify="center">
            <v-col align="center" xs="12" md="6">
                <table cellspacing="5px" class="extra-class">
                    <th xs12 class="text-left white--text text-h5 font-weight-black mt-5">Sosuri:</th>
                    <tr v-for="(item, index) in sosuri" :key="index" style="vertical-align: top">
                        <td class="text-left white--text text-sm-6">{{ item.description }}</td>
                        <td class="text-left white--text" width="100px">
                            <span v-if="!item.reducedPrice"> {{ item.price }} </span>
                            <span v-if="item.reducedPrice">
                                <s> {{ item.price }} </s>
                                <span style="color: red; font-weight: bold; margin-left: 3px"> {{ item.reducedPrice }} </span>
                            </span>
                        </td>
                    </tr>
                </table>
                <table class="mt-3 extra-class" cellspacing="5px">
                    <th xs12 class="text-left white--text text-h5 font-weight-black mt-5">Extra topping:</th>
                    <tr v-for="(item, index) in extraTops" :key="index">
                        <td class="text-left white--text text-sm-6" style="margin-right: 5px">{{ item.description }}</td>
                        <td class="text-left white--text" width="100px">{{ item.price }}</td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BackToExtras',
    data() {
        return {
            sosuri: [
                {
                    description: 'Sos de maioneza cu usturoi 80g',
                    price: '7 lei',
                },
                {
                    description: 'Ketchup dulce 80g',
                    price: '5 lei',
                },
                {
                    description: 'Ketchup picant 80g',
                    price: '5 lei',
                },
            ],
            extraTops: [
                {
                    description: 'Cas       50g',
                    price: '6 lei',
                },
                {
                    description: 'Sunca     50g',
                    price: '6 lei',
                },
                {
                    description: 'Salam  50g',
                    price: '6 lei',
                },
                {
                    description: 'Muschiulet afumat   50g',
                    price: '6 lei',
                },
                {
                    description: 'Cabanos     50g',
                    price: '6 lei',
                },
                {
                    description: 'Emmentaler     50g',
                    price: '6 lei',
                },
                {
                    description: 'Gorgonzola   50g',
                    price: '6 lei',
                },
                {
                    description: 'Ciuperci    50g',
                    price: '4 lei',
                },
                {
                    description: 'Porumb     50g',
                    price: '4 lei',
                },
                {
                    description: 'Prosciuto crudo   50g',
                    price: '10 lei',
                },
                {
                    description: 'Rucola     50g',
                    price: '6 lei',
                },
                {
                    description: 'Ardei iute     20g',
                    price: '2 lei',
                },
                {
                    description: 'Masline   30g',
                    price: '3 lei',
                },
            ],
        };
    },
};
</script>

<style scoped>
.extras-style {
    background: url('../assets/img/bktdvbkd.jpg');
    background-size: cover;
}
.extra-class {
    width: 55%;
}
@media only screen and (max-width: 768px) {
    .extra-class {
        width: 100%;
    }
}
</style>
