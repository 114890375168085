<template>
    <span>
        <v-app-bar fixed app color="brown darken-4" dark clipped-left>
            <v-spacer></v-spacer>
            <!-- <v-spacer></v-spacer>
            <v-spacer></v-spacer> -->
            <v-spacer></v-spacer>
            <div class="mr-2 text-center">tel:</div>
            <div class="mr-5">
                <div class="text-caption text-center">0751-530855</div>
                <div class="text-caption text-center">0751-530823</div>
                <div class="text-caption text-center">0751-888939</div>
            </div>
            <div class="mr-2">
                <div class="text-caption text-center">Orar: <u>L - V 10:00 - 21:00</u></div>
                <div class="text-caption text-center">Orar: <u>S 11:00 - 21:00</u></div>
                <div class="text-caption text-center">(comenzi pana la 20:30)</div>
            </div>
        </v-app-bar>
    </span>
</template>

<script>
export default {
    name: 'AppNavigation',
};
</script>
